import { useMatchesData } from "./useMatches.js";

export function useOptionalUser() {
  const data = useMatchesData("root", false);
  if (!data || !isUser(data.user)) {
    return undefined;
  }
  return data.user;
}

export function useUser() {
  const maybeUser = useOptionalUser();
  if (!maybeUser) {
    throw new Error(
      "No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.",
    );
  }
  return maybeUser;
}

function isUser(user) {
  return user && typeof user === "object" && typeof user.email === "string";
}

export function useUserSubscriptionValue({
  trial = null,
  active = null,
  active_unlimited = active,
  active_flexible = active,
  active_basic = active,
  active_pro = active,
  none = trial,
}) {
  const user = useOptionalUser();

  if (!user) {
    return typeof none === "function" ? none() : none;
  }

  const statuses = {
    active_unlimited,
    active_flexible,
    active_basic,
    active_pro,
    trial,
  };

  const status = statuses[user.subscription.status];

  if (typeof status !== "undefined") {
    return typeof status === "function" ? status(user) : status;
  }

  throw new Error(
    `Unhandled subscription "${user.subscription.status}" for ${user.email} (id: ${user.id})`,
  );
}

export function useUserAvailabilityRequestLimit() {
  const user = useOptionalUser();

  const canSubscribe = useUserSubscriptionValue({
    none: () => false,
    trial: (user) => user.subscription.maxActiveRequests !== 7, // Must be same as in stripe config
    active: () => false,
    active_basic: () => true,
  });

  const limit = user?.subscription.maxActiveRequests;

  return {
    limit,
    canSubscribe,
  };
}
