function maybePluralizeNoun(count, noun, suffix = "s", includeCount = true) {
  if (includeCount) {
    return `${count.toLocaleString()} ${noun}${count !== 1 ? suffix : ""}`;
  }
  return `${noun}${count !== 1 ? suffix : ""}`;
}

function maybePluralizeVerb(nounCount, verb, suffix = "s") {
  return `${verb}${nounCount !== 1 ? "" : suffix}`;
}

function makeId(length = 8) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export { maybePluralizeNoun, maybePluralizeVerb, makeId };
